import * as Sentry from '@sentry/node'

/**
 * Log frontend errors.
 *
 * @param {Object|Error} data
 * @param {string} data.message - A required message
 * @param {string} action - An identifying tag
 * @returns {Promise<void>}
 */
export default async function logError(data, action) {
  if (process.env.NODE_ENV === 'production') {
    try {
      const { message, ...rest } = data || {}

      if (process.env.SENTRY_LOGGING_ENABLED === 'true') {
        Sentry.captureMessage(data instanceof Error ? data : message, {
          level: 'error',
          tags: {
            environment: 'production',
            action,
            ...rest,
          },
        })
        await Sentry.flush(2000)
      }

      if (process.env.ENDPOINT_LOGGING_ENABLED === 'true') {
        const a = encodeURIComponent(action)
        const m = encodeURIComponent(message)
        const d = Object.entries(rest)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&')
        await fetch(
          `${process.env.MAGENTO_DOMAIN}log-endpoint?action=${a}&message=${m}${
            d ? '&' + d : ''
          }`
        )
      }
    } catch (error) {
      console.error('Failed to log error', error)
    }
  } else {
    console.error(action, data)
  }
}
