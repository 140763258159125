import React, { useEffect } from 'react'
import App from 'next/app'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/node'

// The next line is excluded from linting since the file gets generated at runtime.
/* eslint-disable-next-line import/no-unresolved */
import '../public/assets/styles/main.css'

import { fetchEssentialSnippets, fetchMenuData, logError } from '../utils'

if (
  process.env.NEXT_PUBLIC_SENTRY_DSN &&
  process.env.NODE_ENV === 'production'
) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    // `ignoreErrors` was added as fix for https://sentry.io/organizations/alphapet-ventures/issues/2472483501/?project=5632983
    // due to this advice https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],

    beforeSend(event) {
      if (
        event.request &&
        event.request.headers &&
        event.request.headers['User-Agent']
      ) {
        const user_agent = event.request.headers['User-Agent']
        if (
          user_agent ==
          'Links (2.13; Linux 3.10.0-862.2.3.e17.x86_64 x86_64; GNU C 4.8.5; text)'
        ) {
          return null
        }
        if (
          user_agent ==
          'Mozilla/5.0 (compatible; DuckDuckBot-Https/1.1; https://duckduckgo.com/duckduckbot)'
        ) {
          return null
        }
      }
      return event
    },
  })
}

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.GTM })
  }, [])

  if (!pageProps) {
    return null
  }

  const getLayout = Component?.getLayout || ((page) => page)

  return getLayout(<Component {...pageProps} />, pageProps)
}

const onError = (error) => logError(error, 'nextjs:_app')

MyApp.getInitialProps = async (appContext) => {
  // Fetch all that we need in async requests
  const promises = [
    App.getInitialProps(appContext), // Calls page's `getInitialProps` and fills `appProps.pageProps`
    fetchMenuData().catch(onError), // Cached in the local storage on the client-side
  ]
  // Fetch essential snippets only on the server on initial page load because
  // they are being shared between pages and cached on the client-side
  if (typeof window === 'undefined') {
    promises.push(fetchEssentialSnippets().catch(onError))
  }

  const [appProps = { pageProps: {} }, menuData = [], snippets = []] =
    await Promise.all(promises)

  // If `pageProps` is still undefined here, then the page component returned
  // `undefined` during its `getInitialProps` call. This can happen when we're
  // in the process of being redirected from `getInitialProps` of the page
  // component.
  if (!appProps.pageProps) {
    appProps.pageProps = {}
  }

  appProps.pageProps.menuData = menuData
  if (snippets) {
    appProps.pageProps.snippets = snippets
  }

  // Let the client's user-agent decide what version to render on the server-side
  let device = 'desktop'
  if (appContext.ctx?.req) {
    const UAParser = (await import('ua-parser-js')).default
    const ua = new UAParser(appContext.ctx?.req?.headers['user-agent'])
    switch (ua.getDevice().type) {
      case 'tablet':
        device = 'tablet'
        break
      case 'mobile':
        device = 'mobile'
    }
  }
  appProps.pageProps.device = device

  return appProps
}
